import React from 'react';
import { Helmet } from 'react-helmet';
import seoMetadata from './seo_metadata';
import { ComingSoonPage } from './comingSoon';
import LandingPage from './landingPage';

function App() {
    return (
        <>
            <Helmet>
                <title>{seoMetadata.title}</title>
                <meta name="description" content={seoMetadata.description} />
                <meta name="keywords" content={seoMetadata.keywords.join(', ')} />
                {/* Open Graph / Facebook */}
                <meta property="og:type" content={seoMetadata.openGraph.type} />
                <meta property="og:url" content={seoMetadata.openGraph.url} />
                <meta property="og:title" content={seoMetadata.openGraph.title} />
                <meta property="og:description" content={seoMetadata.openGraph.description} />
                <meta property="og:image" content={seoMetadata.openGraph.image} />
                {/* Twitter */}
                <meta name="twitter:card" content={seoMetadata.twitter.card} />
                <meta name="twitter:site" content={seoMetadata.twitter.site} />
                <meta name="twitter:title" content={seoMetadata.twitter.title} />
                <meta name="twitter:description" content={seoMetadata.twitter.description} />
                <meta name="twitter:image" content={seoMetadata.twitter.image} />
            </Helmet>

            {/* <LandingPage /> */}
            <ComingSoonPage />




        </>
    );
}

export default App;