"use client";
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BackgroundBeams } from "./components/ui/bg-beams";//"../ui/background-beams";

export function ComingSoonPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('Submitting...');

        const scriptURL = 'https://script.google.com/macros/s/AKfycbw968vnhol8SWcMHPBGMX1Q9ljosAEPCSVw4VNG4N85EqlozA-JYmQplJG_rMkeYVa2Ww/exec';

        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email=${encodeURIComponent(email)}`,
            });

            setMessage('Thanks for subscribing!');
            setEmail('');
        } catch (error) {
            setMessage('Oops! Something went wrong.');
        }
    };
    return (
        <>
            <style jsx global>{`
                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
                body {
                font-family: 'Inter', sans-serif;
                }
            `}</style>
            <div className="h-screen w-full bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
                <BackgroundBeams />
                <div className="min-h-screen flex flex-col justify-center items-center p-4 text-white font-['Inter',sans-serif]">
                    <motion.h1
                        className="text-7xl md:text-7xl font-extrabold mb-4 text-center"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        Stunning Flutter animations in seconds
                    </motion.h1>

                    <motion.p
                        className="text-5xl md:text-5xl text-center mb-8 max-w-6xl text-gray-300 font-light"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3, duration: 0.8 }}
                    >
                        Make your app beautiful with copy-paste animations. No libraries, no bloat—just code you need.
                    </motion.p>

                    <motion.form
                        onSubmit={handleSubmit}
                        className="w-full max-w-md"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6, duration: 0.8 }}
                    >
                        <div className="flex flex-col sm:flex-row items-center">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => {
                                    console.log('Input changed:', e.target.value);
                                    setEmail(e.target.value)
                                }
                                }
                                className="flex-grow w-full sm:w-auto px-4 py-2 text-white placeholder-gray-400 border border-gray-600 focus:border-blue-500 focus:outline-none rounded-l-md bg-transparent border-4 bg-white text-black"
                                style={{ minHeight: '40px', minWidth: '200px' }}
                                required
                            />
                            <button
                                type="submit"
                                className="w-full sm:w-auto px-6 py-2 border border-blue-500 hover:border-blue-600 transition-colors duration-300 text-blue-500 hover:text-blue-600 font-semibold rounded-r-md mt-2 sm:mt-0"
                            >
                                Get notified at launch
                            </button>
                        </div>
                    </motion.form>
                    {message && (
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="mt-4 text-green-400"
                        >
                            {message}
                        </motion.p>
                    )}

                    {/* <motion.div
                        className="w-full max-w-md"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6, duration: 0.8 }}
                    >
                        <div className="flex flex-col sm:flex-row items-center">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="flex-grow w-full sm:w-auto px-4 py-2 text-white placeholder-gray-400 bg-transparent border border-gray-600 focus:border-blue-500 focus:outline-none rounded-l-md"
                            />
                            <button
                                className="w-full sm:w-auto px-6 py-2 bg-blue-500 hover:bg-blue-600 transition-colors duration-300 text-white font-semibold rounded-r-md mt-2 sm:mt-0"
                            >
                                Get notified at launch
                            </button>
                        </div>
                    </motion.div> */}
                </div>

            </div >
        </>
    );
}

export default ComingSoonPage;